<template>
    <div class="container">
        <div class="mail-box">
            <aside class="sm-side">
                <div class="user-head">
                    <img width="64" hieght="60" src="https://www.iconpacks.net/icons/2/free-sent-email-icon-2579-thumb.png">
                </div>
                <ul class="inbox-nav inbox-divider">
                    <li :class="{'active': filter.status === ''}"> <a href="#" @click="changeStatus()"><i class="fa fa-inbox"></i> All Mail </a></li>
                    <li :class="{'active': filter.status === 'sent'}"> <a href="#" @click="changeStatus('sent')"><i class="fa fa-envelope-o"></i> Sent Mail</a></li>
                    <li :class="{'active': filter.status === 'error'}"> <a href="#" @click="changeStatus('error')"><i class="fa fa-bookmark-o"></i> Failed Mail</a></li>
                </ul>
            </aside>
            <aside class="lg-side">
                <div class="inbox-head">
                    <h3>{{ filter.status === 'error' ? "Failed Emails" : filter.status === 'sent' ? "Sent Emails" : "Emails" }}</h3>
                    <form action="#" class="float-right position">
                        <div class="input-append">
                            <input type="text" class="sr-input" placeholder="Search Mail" v-model="filter.search">
                            <button class="btn sr-btn" type="button" @click="search(1)"><i class="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="inbox-body">
                    <div v-if="preview">
                        <div class="mail-option">
                            <div class="btn-group" style="float: right;">
                                <a ref="#" class="btn mini tooltips" @click="preview = null">
                                    <i class=" fa fa-arrow-left"></i>
                                </a>
                            </div>
                        </div>
                        <div v-html="preview"></div>
                    </div>
                    <div v-else>
                        <div class="mail-option">
                            <div v-if="selected.length" class="btn-group">
                                <a data-original-title="Refresh" data-placement="top" data-toggle="dropdown"
                                    @click="remove" class="btn mini tooltips">
                                    <i class=" fa fa-trash"></i>
                                </a>
                            </div>
                            <ul class="unstyled inbox-pagination">
                                <li><span>{{ (page * max) - (max - 1) }}-{{ page * max  }} of {{ items.total }}</span>
                                </li>
                                <li> <a class="np-btn" href="#" @click="search(page - 1)"><i class="fa fa-angle-left  pagination-left"></i></a>
                                </li>
                                <li> <a class="np-btn" href="#" @click="search(page + 1)"><i class="fa fa-angle-right pagination-right"></i></a>
                                </li>
                            </ul>
                        </div>
                        <table class="table table-inbox table-hover">
                            <tbody>
                            <tr @click="view(data._id)" class="" v-for="data in items.data" v-bind:key="data._id">
                                <td @click.stop="" class="inbox-small-cells">
                                    <input type="checkbox" class="mail-form-check" v-model="selected" :value="data._id">
                                </td>
                                <td class="view-message  dont-show">{{ data.to }}</td>
                                <td class="view-message ">{{ data.subject }}</td>
                                <td class="view-message  text-right">{{ data.created_ts | userTime(user.timezone)}}</td>
                                <td v-if="data.status === 'error'" class="view-message inbox-small-cells" @click.stop="showError(data.reason)"><i class="fa fa-info"></i></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
//import vPagination from 'vue-plain-pagination'
export default {
    name: "Email",
    props:['user'],
    selectedItems:[],
    components: {
        //vPagination
    },
    data: function () {
        return {
            selected: [],
            item: {
                notifications: []
            },
            preview: null,
            items: {total:0, data:[]},
            editor: false,
            filter: {name:'', status:''},
            filters: {},
            roles:[],
            max: 25,
            page: 1,
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            searching: false
        }
    },
    created: function(){
        this.search();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.editor && !this.searching){
                this.search();
            }
        },
        showError(reason) {
            swal({title:"Reason", text: reason});
        },
        changeStatus(status) {
            this.preview = null;
            this.filter.status = status;
            this.search(1) ;
        },
        remove() {
            axios.post(`${this.$root.serverUrl}/admin/emails/bulk/remove`,{emails: this.selected}).then(() => {
                this.selected = [];
                this.search(this.page);
            }).catch((err) => {
                console.log(err)
                swal({title:"Warning", text: "There was a problem while trying to process your request", icon:"error"});
            });
        },
        view(id) {
            axios.get(`${this.$root.serverUrl}/admin/emails/view/${id}`,{}).then((resp) => {
                console.log(resp)
                this.preview = resp.data.template;
            }).catch((err) => {
                console.log(err)
                swal({title:"Warning", text: "There was a problem while trying to display preview", icon:"error"});
            });
        },
        search: function(page){
            this.searching = true;
            this.$root.preloader = true;

            var request = {filters:{}};
            if(this.filter.search) request.sSearch = this.filter.search;
            if(this.filter.status) request.filters.status = this.filter.status;
            request.page = page ? page : this.page;
            request.max = this.max;
            //this.$root.preloader = true;

            axios.get(`${this.$root.serverUrl}/admin/reports/email/logs`,{params:request}).then((resp) => {
                //Store the stats
                this.items = (resp.data.data)? {total: resp.data.total, data: resp.data.data} : {total:0, data:[]};
                this.$root.preloader = false;
                this.searching = false;
                this.page = page ? page : this.page;
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: "There was a problem while trying to display results", icon:"error"});
            });
        },
    }
}
</script>

<style scoped>
    .mail-box {
        border-collapse: collapse;
        border-spacing: 0;
        display: table;
        table-layout: fixed;
        width: 100%;
    }
    .mail-box aside {
        display: table-cell;
        float: none;
        height: 100%;
        padding: 0;
        vertical-align: top;
    }
    .mail-box .sm-side {
        background: none repeat scroll 0 0 #e5e8ef;
        border-radius: 4px 0 0 4px;
        width: 25%;
    }
    .mail-box .lg-side {
        background: none repeat scroll 0 0 #fff;
        border-radius: 0 4px 4px 0;
        width: 75%;
    }
    .mail-box .sm-side .user-head {
        background: none repeat scroll 0 0 #5b5e5e;
        border-radius: 4px 0 0;
        color: #fff;
        min-height: 80px;
        padding: 10px;
    }
    .user-head .inbox-avatar {
        float: left;
        width: 65px;
    }
    .user-head .inbox-avatar img {
        border-radius: 4px;
    }
    .user-head .user-name {
        display: inline-block;
        margin: 0 0 0 10px;
    }
    .user-head .user-name h5 {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 15px;
    }
    .user-head .user-name h5 a {
        color: #fff;
    }
    .user-head .user-name span a {
        color: #87e2e7;
        font-size: 12px;
    }
    a.mail-dropdown {
        background: none repeat scroll 0 0 #80d3d9;
        border-radius: 2px;
        color: #01a7b3;
        font-size: 10px;
        margin-top: 20px;
        padding: 3px 5px;
    }
    .inbox-body {
        padding: 20px;
    }
    .btn-compose {
        background: none repeat scroll 0 0 #ff6c60;
        color: #fff;
        padding: 12px 0;
        text-align: center;
        width: 100%;
    }
    .btn-compose:hover {
        background: none repeat scroll 0 0 #f5675c;
        color: #fff;
    }
    ul.inbox-nav {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .inbox-divider {
        border-bottom: 1px solid #d5d8df;
    }
    ul.inbox-nav li {
        display: inline-block;
        line-height: 45px;
        width: 100%;
    }
    ul.inbox-nav li a {
        color: #6a6a6a;
        display: inline-block;
        line-height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    ul.inbox-nav li a:hover, ul.inbox-nav li.active a, ul.inbox-nav li a:focus {
        background: none repeat scroll 0 0 #d5d7de;
        color: #6a6a6a;
    }
    ul.inbox-nav li a i {
        color: #6a6a6a;
        font-size: 16px;
        padding-right: 10px;
    }
    ul.inbox-nav li a span.label {
        margin-top: 13px;
    }
    ul.labels-info li h4 {
        color: #5c5c5e;
        font-size: 13px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        text-transform: uppercase;
    }
    ul.labels-info li {
        margin: 0;
    }
    ul.labels-info li a {
        border-radius: 0;
        color: #6a6a6a;
    }
    ul.labels-info li a:hover, ul.labels-info li a:focus {
        background: none repeat scroll 0 0 #d5d7de;
        color: #6a6a6a;
    }
    ul.labels-info li a i {
        padding-right: 10px;
    }
    .nav.nav-pills.nav-stacked.labels-info p {
        color: #9d9f9e;
        font-size: 11px;
        margin-bottom: 0;
        padding: 0 22px;
    }
    .inbox-head {
        background: none repeat scroll 0 0 #6f7171;
        border-radius: 0 4px 0 0;
        color: #fff;
        min-height: 80px;
        padding: 20px;
    }
    .inbox-head h3 {
        display: inline-block;
        font-weight: 300;
        margin: 0;
        padding-top: 6px;
    }
    .inbox-head .sr-input {
        border: medium none;
        border-radius: 4px 0 0 4px;
        box-shadow: none;
        color: #8a8a8a;
        float: left;
        height: 40px;
        padding: 0 10px;
    }
    .inbox-head .sr-btn {
        background: none repeat scroll 0 0 #00a6b2;
        border: medium none;
        border-radius: 0 4px 4px 0;
        color: #fff;
        height: 40px;
        padding: 0 20px;
    }
    .table-inbox {
        border: 1px solid #d3d3d3;
        margin-bottom: 0;
    }
    .table-inbox tr td {
        padding: 12px !important;
    }
    .table-inbox tr td:hover {
        cursor: pointer;
    }
    .table-inbox tr td .fa-star.inbox-started, .table-inbox tr td .fa-star:hover {
        color: #f78a09;
    }
    .table-inbox tr td .fa-star {
        color: #d5d5d5;
    }
    .table-inbox tr.unread td {
        background: none repeat scroll 0 0 #f7f7f7;
        font-weight: 600;
    }
    ul.inbox-pagination {
        float: right;
    }
    ul.inbox-pagination li {
        float: left;
    }
    .mail-option {
        display: inline-block;
        margin-bottom: 10px;
        width: 100%;
    }
    .mail-option .chk-all, .mail-option .btn-group {
        margin-right: 5px;
    }
    .mail-option .chk-all, .mail-option .btn-group a.btn {
        background: none repeat scroll 0 0 #fcfcfc;
        border: 1px solid #e7e7e7;
        border-radius: 3px !important;
        color: #afafaf;
        display: inline-block;
        padding: 5px 10px;
    }
    .inbox-pagination a.np-btn {
        background: none repeat scroll 0 0 #fcfcfc;
        border: 1px solid #e7e7e7;
        border-radius: 3px !important;
        color: #afafaf;
        display: inline-block;
        padding: 5px 15px;
    }
    .mail-option .chk-all input[type="checkbox"] {
        margin-top: 0;
    }
    .mail-option .btn-group a.all {
        border: medium none;
        padding: 0;
    }
    .inbox-pagination a.np-btn {
        margin-left: 5px;
    }
    .inbox-pagination li span {
        display: inline-block;
        margin-right: 5px;
        margin-top: 7px;
    }
    .fileinput-button {
        background: none repeat scroll 0 0 #eeeeee;
        border: 1px solid #e6e6e6;
    }
    .inbox-body .modal .modal-body input, .inbox-body .modal .modal-body textarea {
        border: 1px solid #e6e6e6;
        box-shadow: none;
    }
    .btn-send, .btn-send:hover {
        background: none repeat scroll 0 0 #00a8b3;
        color: #fff;
    }
    .btn-send:hover {
        background: none repeat scroll 0 0 #009da7;
    }
    .modal-header h4.modal-title {
        font-family: "Open Sans",sans-serif;
        font-weight: 300;
    }
    .modal-body label {
        font-family: "Open Sans",sans-serif;
        font-weight: 400;
    }
    .heading-inbox h4 {
        border-bottom: 1px solid #ddd;
        color: #444;
        font-size: 18px;
        margin-top: 20px;
        padding-bottom: 10px;
    }
    .sender-info {
        margin-bottom: 20px;
    }
    .sender-info img {
        height: 30px;
        width: 30px;
    }
    .sender-dropdown {
        background: none repeat scroll 0 0 #eaeaea;
        color: #777;
        font-size: 10px;
        padding: 0 3px;
    }
    .view-mail a {
        color: #ff6c60;
    }
    .attachment-mail {
        margin-top: 30px;
    }
    .attachment-mail ul {
        display: inline-block;
        margin-bottom: 30px;
        width: 100%;
    }
    .attachment-mail ul li {
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        width: 150px;
    }
    .attachment-mail ul li img {
        width: 100%;
    }
    .attachment-mail ul li span {
        float: right;
    }
    .attachment-mail .file-name {
        float: left;
    }
    .attachment-mail .links {
        display: inline-block;
        width: 100%;
    }

    .fileinput-button {
        float: left;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
    }
    .fileinput-button input {
        cursor: pointer;
        direction: ltr;
        font-size: 23px;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-300px, 0px) scale(4);
    }
    .fileupload-buttonbar .btn, .fileupload-buttonbar .toggle {
        margin-bottom: 5px;
    }
    .files .progress {
        width: 200px;
    }
    .fileupload-processing .fileupload-loading {
        display: block;
    }
    * html .fileinput-button {
        line-height: 24px;
        margin: 1px -3px 0 0;
    }
    * + html .fileinput-button {
        margin: 1px 0 0;
        padding: 2px 15px;
    }
    @media (max-width: 767px) {
        .files .btn span {
            display: none;
        }
        .files .preview * {
            width: 40px;
        }
        .files .name * {
            display: inline-block;
            width: 80px;
            word-wrap: break-word;
        }
        .files .progress {
            width: 20px;
        }
        .files .delete {
            width: 60px;
        }
    }
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }
</style>
